import { VehicleFile, VehicleAnswer } from '../types';
import { Thunk, Dispatch } from '../../../types';
import { fetchVehicleFailed, fetchVehicleRequested, fetchVehicleSucceeded, fetchVehicleEmpty } from '../actions';
import { fetchData } from '../../../configuration/setup/fetch';
import { config } from '../../../config';
import { get, isEqual } from 'lodash';
import { store } from '../../..//configuration/setup/store';

export const fetchVehicleFile =
    (vehicleId: string): Thunk =>
    async (dispatch: Dispatch) => {
        try {
            const requestDate = Date.now();
            dispatch(fetchVehicleRequested(requestDate));

            const requestUrl = `${config.backend.FILE_ARCHIVE_URL}`;
            const answer = await fetchData<VehicleAnswer>(requestUrl, {
                method: 'POST',
                body: JSON.stringify({
                    externalEquipmentReferences: [vehicleId],
                    fileType: 'Vehicle',
                }),
            });

            const vehicleFile = get(answer, 'items[0]');
            const vehicleEmpty = isEqual(get(answer, 'items'), []);
            if (store.getState().app.fetchVehicleRequested === requestDate) {
                if (vehicleEmpty) {
                    dispatch(fetchVehicleEmpty());
                } else {
                    if (vehicleFile) {
                        dispatch(fetchVehicleSucceeded(vehicleFile as VehicleFile));
                    } else {
                        dispatch(fetchVehicleFailed());
                    }
                }
            }
        } catch (e) {
            dispatch(fetchVehicleFailed());
        }
    };
