import { ConfigState } from './types';

const getBoolEnvValue = (envValue: string): boolean => {
    return typeof process.env[envValue] !== 'undefined' && process.env[envValue] === 'true';
};

export const config: ConfigState = {
    backend: {
        AUTHENTICATION_SERVICE: process.env.REACT_APP_AUTHENTICATION_SERVICE,
        MENU_SERVICE: process.env.REACT_APP_MENU_SERVICE,
        REACT_APP_ASSET_LIVE_STATE: process.env.REACT_APP_ASSET_LIVE_STATE,
        FILE_ARCHIVE_URL: process.env.REACT_APP_FILE_ARCHIVE_URL,
        REACT_APP_VEHICLES_WITH_SUBSCRIPTION_URL: process.env.REACT_APP_VEHICLES_WITH_SUBSCRIPTION_URL,
        REACT_APP_ASSET_ADMINISTRATION_URL: process.env.REACT_APP_ASSET_ADMINISTRATION_URL,
        REACT_APP_DRIVER_ADMINISTRATION_URL: process.env.REACT_APP_DRIVER_ADMINISTRATION_URL,
    },
    homeRoute: process.env.REACT_APP_HOME_ROUTE,
    id: process.env.REACT_APP_ID,
    login: {
        authority: process.env.REACT_APP_LOGIN_AUTHORITY,
        clientId: 'b8a414b3-6f71-453e-b53b-6c02a2925004',
        oauthScope: [' '],
        mockAuthorization: getBoolEnvValue('REACT_APP_LOGIN_MOCK_AUTHORIZATION'),
        mockLocale: process.env.REACT_APP_LOGIN_MOCK_LOCALE,
        preventRedirect: getBoolEnvValue('REACT_APP_LOGIN_PREVENT_REDIRECT'),
        redirectUri: process.env.REACT_APP_LOGIN_REDIRECT_URI,
        silentRedirectUri: process.env.REACT_APP_LOGIN_SILENT_REDIRECT_URI,
    },
    logoutUri: process.env.REACT_APP_LOGOUT_URI,
    sentryToken: 'https://e7b9c18d15e34ce98c86993026c3bf05@sentry.io/3446955',
};
