import { Thunk, Dispatch } from '../../../types';
import { fetchSubscriptionsSucceeded } from '../actions';
import { fetchData } from '../../../configuration/setup/fetch';
import { config } from '../../../config';

export const fetchVehiclesWithSubscription = (): Thunk => async (dispatch: Dispatch) => {
    try {
        const requestUrl = `${config.backend.REACT_APP_VEHICLES_WITH_SUBSCRIPTION_URL}`;
        const answer = await fetchData<string[]>(requestUrl);

        if (answer) {
            dispatch(fetchSubscriptionsSucceeded(answer));
        }
    } catch (e) {}
};
