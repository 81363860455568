import { PositionInfo } from '../types';
import { Thunk, Dispatch } from '../../../types';
import { fetchDriverIdRequested, fetchDriverIdFailed, fetchDriverIdSucceeded } from '../actions';
import { fetchData } from '../../../configuration/setup/fetch';
import { config } from '../../../config';
import { get } from 'lodash';
import { store } from '../../..//configuration/setup/store';

export const fetchDriverId =
    (vehicleId: string): Thunk =>
    async (dispatch: Dispatch) => {
        try {
            const requestDate = Date.now();
            dispatch(fetchDriverIdRequested(requestDate));

            const requestUrl = `${config.backend.REACT_APP_ASSET_LIVE_STATE}${vehicleId}`;
            const answer = await fetchData<PositionInfo>(requestUrl);

            const driverId = get(answer, 'driver_id.value');
            if (store.getState().app.fetchDriverIdRequested === requestDate) {
                if (driverId) {
                    dispatch(fetchDriverIdSucceeded(driverId));
                } else {
                    dispatch(fetchDriverIdFailed());
                }
            }
        } catch (e) {
            dispatch(fetchDriverIdFailed());
        }
    };
