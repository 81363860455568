import { useState } from 'react';
import ExpanderPanel from '@rio-cloud/rio-uikit/lib/es/ExpanderPanel';
import EmptyState from '@rio-cloud/rio-uikit/lib/es/EmptyState';
import FileDisplay from './FileDisplay';
import { VehicleFile, DriverFile } from '../types';
import { FormattedMessage } from 'react-intl';
import IframeResizer from 'iframe-resizer-react';

const App = (props: Props) => {
    const {
        selectedVehicleId,
        subscriptions,
        vehicleFile,
        vehicleFileFailed,
        vehicleFileRequested,
        vehicleFileEmpty,
        driverIdRequested,
        driverIdFailed,
        driverIds,
        driverFile,
        driverFileFailed,
        driverFileRequested,
        driverFileEmpty,
        driverDisplayName,
        vehicleName,
    } = props;

    const [open, setOpen] = useState(true);
    const onToggle = () => setOpen(!open);

    const Panel = (panelProps: any) => (
        <ExpanderPanel
            title={<FormattedMessage id={'widget.lastRemoteDownload'} defaultMessage={'Last Remote Download'} />}
            bodyClassName={'padding-0'}
            open={open}
            onToggle={onToggle}
            titleClassName={'text-bold'}
            bsStyle={'default'}
        >
            {panelProps.children}
        </ExpanderPanel>
    );

    if (!selectedVehicleId) {
        return (
            <Panel>
                <div className={'padding-15'}>
                    <EmptyState
                        outerClassName={'border-none'}
                        condensed
                        headline={
                            <FormattedMessage id={'widget.noVehicleSelected'} defaultMessage={'No vehicle selected'} />
                        }
                        message={
                            <FormattedMessage
                                id={'widget.pleaseSelectVehicle'}
                                defaultMessage={'Please select a vehicle to show its last remote download.'}
                            />
                        }
                    />
                </div>
            </Panel>
        );
    }

    const isNotBooked =
        subscriptions && selectedVehicleId && !subscriptions.some((vehicle) => vehicle.assetId === selectedVehicleId);

    if (isNotBooked) {
        return (
            <Panel>
                <IframeResizer
                    height={0}
                    src={
                        'https://upselling.marketplace.rio.cloud/' +
                        '?productId=man-sku00000004' +
                        '&level=10' +
                        '&internalCampaign=compliant-widget-upselling' +
                        `&resourceId=${selectedVehicleId}` +
                        '&noBorder=true'
                    }
                />
            </Panel>
        );
    }

    const showDriver = !driverIdRequested && !driverIdFailed && driverIds.length;

    return (
        <Panel>
            <div>
                <FileDisplay
                    externalReference={selectedVehicleId}
                    transferDate={new Date(vehicleFile?.timeCreatedUtc || Date.now())}
                    name={vehicleName || vehicleFile?.vehicleInfo?.vin}
                    type={'tachograph'}
                    empty={vehicleFileEmpty}
                    failed={vehicleFileFailed}
                    requested={vehicleFileRequested}
                />
                {showDriver && <hr className={'border-color-lighter border-width-1 margin-0'} />}
                {showDriver && (
                    <FileDisplay
                        externalReference={driverIds.join(',')}
                        transferDate={new Date(driverFile?.timeCreatedUtc || Date.now())}
                        name={driverFile?.driverInfo?.driverCardNumber}
                        displayName={driverDisplayName}
                        type={'driver'}
                        empty={driverFileEmpty}
                        failed={driverFileFailed}
                        requested={driverFileRequested}
                    />
                )}
            </div>
        </Panel>
    );
};

type Props = {
    vehicleFile?: VehicleFile;
    vehicleFileFailed: boolean;
    vehicleFileRequested: boolean;
    vehicleFileEmpty: boolean;
    driverIdFailed: boolean;
    driverIdRequested: boolean;
    driverIds: string[];
    driverFile?: DriverFile;
    driverFileFailed: boolean;
    driverFileRequested: boolean;
    driverFileEmpty: boolean;
    driverDisplayName: string;
    vehicleName?: string;
    selectedVehicleId?: string | false;
    subscriptions?: any[] | false;
    userLocale: string;
};

export default App;
