import { createAction } from 'redux-actions';
import { DRIVER_IDS_SUCCEEDED, DriverFile, VehicleFile } from './types';

export const HIDE_SESSION_EXPIRED_DIALOG = 'app/HIDE_SESSION_EXPIRED_DIALOG';

export const FETCH_VEHICLE_REQUESTED = 'app/FETCH_VEHICLE_REQUESTED';
export const FETCH_VEHICLE_SUCCEEDED = 'app/FETCH_VEHICLE_SUCCEEDED';
export const FETCH_VEHICLE_FAILED = 'app/FETCH_VEHICLE_FAILED';
export const FETCH_VEHICLE_EMPTY = 'app/FETCH_VEHICLE_EMPTY';

export const FETCH_SUBSCRIPTIONS_SUCCEEDED = 'app/FETCH_SUBSCRIPTIONS_SUCCEEDED';

export const FETCH_DRIVER_ID_REQUESTED = 'app/FETCH_DRIVER_ID_REQUESTED';
export const FETCH_DRIVER_ID_SUCCEEDED = 'app/FETCH_DRIVER_ID_SUCCEEDED';
export const FETCH_DRIVER_ID_FAILED = 'app/FETCH_DRIVER_ID_FAILED';

export const FETCH_DRIVER_IDS_SUCCEEDED = 'app/FETCH_DRIVER_IDS_SUCCEEDED';

export const FETCH_DRIVER_REQUESTED = 'app/FETCH_DRIVER_REQUESTED';
export const FETCH_DRIVER_SUCCEEDED = 'app/FETCH_DRIVER_SUCCEEDED';
export const FETCH_DRIVER_FAILED = 'app/FETCH_DRIVER_FAILED';
export const FETCH_DRIVER_EMPTY = 'app/FETCH_DRIVER_EMPTY';

export const FETCH_VEHICLE_NAME_REQUESTED = 'app/FETCH_VEHICLE_NAME_REQUESTED';
export const FETCH_VEHICLE_NAME_SUCCEEDED = 'app/FETCH_VEHICLE_NAME_SUCCEEDED';

export const hideSessionExpiredDialog = createAction(HIDE_SESSION_EXPIRED_DIALOG);

export const fetchVehicleRequested = createAction(FETCH_VEHICLE_REQUESTED);
export const fetchVehicleSucceeded = createAction<VehicleFile>(FETCH_VEHICLE_SUCCEEDED);
export const fetchVehicleFailed = createAction(FETCH_VEHICLE_FAILED);
export const fetchVehicleEmpty = createAction(FETCH_VEHICLE_EMPTY);

export const fetchDriverIdRequested = createAction(FETCH_DRIVER_ID_REQUESTED);
export const fetchDriverIdFailed = createAction(FETCH_DRIVER_ID_FAILED);
export const fetchDriverIdSucceeded = createAction<string>(FETCH_DRIVER_ID_SUCCEEDED);

export const fetchDriverIdsSucceeded = createAction<DRIVER_IDS_SUCCEEDED>(FETCH_DRIVER_IDS_SUCCEEDED);

export const fetchSubscriptionsSucceeded = createAction(FETCH_SUBSCRIPTIONS_SUCCEEDED);

export const fetchDriverRequested = createAction(FETCH_DRIVER_REQUESTED);
export const fetchDriverSucceeded = createAction<DriverFile>(FETCH_DRIVER_SUCCEEDED);
export const fetchDriverFailed = createAction(FETCH_DRIVER_FAILED);
export const fetchDriverEmpty = createAction(FETCH_DRIVER_EMPTY);

export const fetchVehicleNameRequested = createAction<number>(FETCH_VEHICLE_NAME_REQUESTED);
export const fetchVehicleNameSucceeded = createAction<string>(FETCH_VEHICLE_NAME_SUCCEEDED);
