import { createAction } from 'redux-actions';

export const selectAssetType = 'EVENT_SELECT_ASSET';

interface AssetSelectedPayload {
    id: string;
}

export const remoteActions = {
    widgetLoaded: createAction<void>('EVENT_WIDGET_LOADED'),
    setWidgetId: createAction<string>('EVENT_SET_WIDGET_ID'),
    setVisibleWidgets: createAction<string[]>('EVENT_SET_VISIBLE_WIDGETS'),
    assetSelected: createAction<AssetSelectedPayload>(selectAssetType),
};
