import { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { connect, ConnectedProps } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import NotificationsContainer from '@rio-cloud/rio-uikit/lib/es/NotificationsContainer';

import { Dispatch, State } from '../../types';
import { config } from '../../config';
import { getIdToken, getTenant, getLanguageData, getLocale, isUserSessionExpired } from '../../configuration';
import {
    getSelectedVehicleId,
    getSessionExpiredAcknowledged,
    getVehicleFile,
    getSubscriptions,
    getFetchVehicleFailed,
    getFetchVehicleRequested,
    getFetchVehicleEmpty,
    getFetchDriverIdFailed,
    getFetchDriverIdRequested,
    getDriverFile,
    getFetchDriverRequested,
    getFetchDriverFailed,
    getFetchDriverEmpty,
    getDriverIds,
    getVehicleName,
    getDriverDisplayName,
} from './selector';
import MessageHandlerContainer, { sendMessage } from './MessageHandler';
import { remoteActions } from './remoteActions';

import AppComponent from './components/App';
import { fetchSubscriptionsSucceeded, fetchVehicleFailed, fetchVehicleEmpty } from './actions';
import { ApplicationLayout } from '@rio-cloud/rio-uikit';

const COMPLIANT_WIDGET_EXCLUDED_TENANTS = ['rio-brazil', 'rio-southkorea'];

const App = (props: Props) => {
    const {
        languageData,
        userLocale,
        tenant,
        selectedVehicleId,
        subscriptions,
        vehicleFile,
        vehicleFileRequested,
        vehicleFileFailed,
        vehicleFileEmpty,
        driverIdFailed,
        driverIdRequested,
        driverIds,
        vehicleName,
        driverFile,
        driverFileRequested,
        driverFileFailed,
        driverFileEmpty,
        driverDisplayName,
        selectRandomVehicle,
        selectBookedVehicle,
        setVehiclesWithSubscription,
        setFailedState,
        setEmptyState,
    } = props;

    useEffect(() => {
        sendMessage(remoteActions.widgetLoaded());
    }, []);

    return COMPLIANT_WIDGET_EXCLUDED_TENANTS.includes(tenant) ? (
        <div></div>
    ) : (
        <IntlProvider defaultLocale={'en-GB'} key={userLocale} locale={userLocale} messages={languageData}>
            <ApplicationLayout>
                <NotificationsContainer stacked={true} />
                <MessageHandlerContainer />
                {process.env.NODE_ENV === 'development' && (
                    <div className={'margin-20'}>
                        <button onClick={selectRandomVehicle} className={'margin-20'}>
                            {'select random vehicle'}
                        </button>
                        <button onClick={selectBookedVehicle} className={'margin-20'}>
                            {'select booked vehicle'}
                        </button>
                        <button onClick={setVehiclesWithSubscription} className={'margin-20'}>
                            {'set vehicles with subscription'}
                        </button>
                        <button onClick={setFailedState} className={'margin-20'}>
                            {'set failed state'}
                        </button>
                        <button onClick={setEmptyState} className={'margin-20'}>
                            {'set empty state'}
                        </button>
                    </div>
                )}
                <Switch>
                    <Route
                        path={'/fileDetails'}
                        component={() => (
                            <AppComponent
                                selectedVehicleId={selectedVehicleId}
                                subscriptions={subscriptions}
                                vehicleFile={vehicleFile}
                                vehicleFileFailed={vehicleFileFailed}
                                vehicleFileRequested={vehicleFileRequested}
                                vehicleFileEmpty={vehicleFileEmpty}
                                driverIdRequested={driverIdRequested}
                                driverIdFailed={driverIdFailed}
                                driverIds={driverIds}
                                driverFile={driverFile}
                                driverFileRequested={driverFileRequested}
                                driverFileFailed={driverFileFailed}
                                driverFileEmpty={driverFileEmpty}
                                driverDisplayName={driverDisplayName}
                                vehicleName={vehicleName}
                                userLocale={userLocale}
                            />
                        )}
                    />
                    <Redirect to={'/fileDetails'} />
                </Switch>
            </ApplicationLayout>
        </IntlProvider>
    );
};

type Props = ConnectedProps<typeof connector>;

const mapStateToProps = (state: State) => ({
    idToken: getIdToken(state),
    tenant: getTenant(state).split('.')[0],
    homeRoute: config.homeRoute as string,
    languageData: getLanguageData(state),
    showSessionExpired: isUserSessionExpired(state) && !getSessionExpiredAcknowledged(state),
    userLocale: getLocale(state),
    vehicleFile: getVehicleFile(state),
    vehicleFileRequested: getFetchVehicleRequested(state),
    vehicleFileFailed: getFetchVehicleFailed(state),
    vehicleFileEmpty: getFetchVehicleEmpty(state),
    driverIdFailed: getFetchDriverIdFailed(state),
    driverIdRequested: getFetchDriverIdRequested(state),
    driverIds: getDriverIds(state),
    driverFile: getDriverFile(state),
    driverFileRequested: getFetchDriverRequested(state),
    driverFileFailed: getFetchDriverFailed(state),
    driverFileEmpty: getFetchDriverEmpty(state),
    driverDisplayName: getDriverDisplayName(state),
    selectedVehicleId: getSelectedVehicleId(state),
    vehicleName: getVehicleName(state),
    subscriptions: getSubscriptions(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    selectRandomVehicle: () => dispatch(remoteActions.assetSelected({ id: Math.random().toString() })),
    selectBookedVehicle: () => dispatch(remoteActions.assetSelected({ id: 'test' })),
    setVehiclesWithSubscription: () => dispatch(fetchSubscriptionsSucceeded(['test'])),
    setFailedState: () => dispatch(fetchVehicleFailed()),
    setEmptyState: () => dispatch(fetchVehicleEmpty()),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
const AppContainer = connector(App);
export default AppContainer;
