import { FormattedDate, FormattedMessage } from 'react-intl';
import ContentLoader from 'react-content-loader';
import EmptyState from '@rio-cloud/rio-uikit/lib/es/EmptyState';
import ErrorState from '@rio-cloud/rio-uikit/lib/es/ErrorState';

const RDBasePath = 'https://remotedownload.rio.cloud/#archive?onlyLatest=true';

const FileDisplay = (props: Props) => {
    const { transferDate, name, displayName, type, requested, failed, externalReference, empty } = props;

    if (requested) {
        return (
            <div className={'padding-15'}>
                <ContentLoader width={250} height={67}>
                    <rect x={0} y={0} rx={5} ry={5} width={40} height={40} />
                    <rect x={50} y={0} rx={3} ry={3} width={100} height={13} />
                    <rect x={50} y={20} rx={3} ry={3} width={200} height={20} />
                    <rect x={50} y={50} rx={3} ry={3} width={200} height={16} />
                </ContentLoader>
            </div>
        );
    }

    if (empty) {
        return (
            <div className={'padding-15'}>
                <EmptyState
                    outerClassName={'border-none'}
                    condensed
                    headline={
                        <FormattedMessage
                            id={type === 'tachograph' ? 'widget.noTachographFileFound' : 'widget.noDriverFileFound'}
                            defaultMessage={'No tachograph file found'}
                        />
                    }
                    message={
                        <FormattedMessage
                            id={'widget.pleaseCheckYourSetup'}
                            defaultMessage={'Please check your setup.'}
                        />
                    }
                    buttons={[
                        {
                            text: (
                                <span>
                                    <span className={'rioglyph rioglyph-link margin-right-5'} />
                                    {'Compliant'}
                                </span>
                            ),
                            onClick: () => window.open('https://remotedownload.rio.cloud/#guidance'),
                        },
                    ]}
                />
            </div>
        );
    }

    if (failed) {
        return (
            <div className={'padding-15'}>
                <ErrorState
                    outerClassName={'border-none'}
                    condensed
                    headline={
                        <FormattedMessage id={'widget.somethingWentWrong'} defaultMessage={'Something went wrong'} />
                    }
                    message={
                        <FormattedMessage
                            id={'widget.pleaseTryAgainLater'}
                            defaultMessage={'Please try again later.'}
                        />
                    }
                />
            </div>
        );
    }

    return (
        <div className={'display-flex align-items-start text-size-14 padding-15'}>
            <span className={'text-size-18 margin-right-10 padding-top-2'}>
                <span className={`rioglyph rioglyph-${type} text-size-200pct text-muted`} />
            </span>
            <div className={'flex-1-1'}>
                <div className={'text-size-12 text-muted'}>
                    <FormattedDate value={transferDate} />
                </div>
                <div className={'text-size-18 text-medium line-height-125rel'}>{name}</div>
                <div className={'text-size-14 text-muted'}>{displayName}</div>
                {type === 'tachograph' && (
                    <button
                        className={'btn btn-link btn-link-inline'}
                        onClick={() =>
                            window.open(
                                // eslint-disable-next-line
                                `${RDBasePath}&currentCategoryId=AssetTree-vehicles&selectedVehicles=${externalReference}`
                            )
                        }
                    >
                        <FormattedMessage
                            defaultMessage={'Show tachograph file in archive'}
                            id={'widget.showTachographFile'}
                        />
                    </button>
                )}
                {type === 'driver' && (
                    <button
                        className={'btn btn-link btn-link-inline'}
                        onClick={() =>
                            window.open(
                                // eslint-disable-next-line
                                `${RDBasePath}&currentCategoryId=AssetTree-drivers&selectedDrivers=${externalReference}`
                            )
                        }
                    >
                        <FormattedMessage
                            defaultMessage={'Show driver card file in archive'}
                            id={'widget.showDriverCardFile'}
                        />
                    </button>
                )}
            </div>
        </div>
    );
};

type Props = {
    externalReference?: string | false;
    transferDate?: Date;
    name?: string;
    displayName?: string;
    type?: 'tachograph' | 'driver';
    empty: boolean;
    failed: boolean;
    requested: boolean;
};

export default FileDisplay;
