import { Asset } from '../types';
import { Thunk, Dispatch } from '../../../types';
import { fetchVehicleNameRequested, fetchVehicleNameSucceeded } from '../actions';
import { fetchData } from '../../../configuration/setup/fetch';
import { config } from '../../../config';
import { get } from 'lodash';
import { store } from '../../../configuration/setup/store';

export const fetchVehicleName =
    (assetId: string): Thunk =>
    async (dispatch: Dispatch) => {
        try {
            const requestDate = Date.now();
            dispatch(fetchVehicleNameRequested(requestDate));

            const requestUrl = `${config.backend.REACT_APP_ASSET_ADMINISTRATION_URL}${assetId}`;
            const answer = await fetchData<Asset>(requestUrl);

            const name = get(answer, 'name');
            if (store.getState().app.fetchVehicleNameRequested === requestDate) {
                dispatch(fetchVehicleNameSucceeded(name as string));
            }
        } catch (e) {}
    };
