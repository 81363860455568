import get from 'lodash/fp/get';

import { State } from '../../types';
import { DriverFile, VehicleFile } from './types';

const BASE = 'app';

export const getSessionExpiredAcknowledged: (state: State) => boolean = get(`${BASE}.sessionExpiredAcknowledged`);

export const getSelectedVehicleId: (state: State) => string | false = get(`${BASE}.selectedVehicleId`);
export const getDriverId: (state: State) => string | false = get(`${BASE}.driverId`);
export const getDriverIds: (state: State) => string[] = get(`${BASE}.driverIds`);
export const getDriverDisplayName: (state: State) => string = get(`${BASE}.driverDisplayName`);

export const getFetchVehicleRequested: (state: State) => boolean = get(`${BASE}.fetchVehicleRequested`);
export const getFetchVehicleFailed: (state: State) => boolean = get(`${BASE}.fetchVehicleFailed`);
export const getFetchVehicleEmpty: (state: State) => boolean = get(`${BASE}.fetchVehicleEmpty`);
export const getVehicleFile: (state: State) => VehicleFile | undefined = get(`${BASE}.vehicleFile`);

export const getFetchDriverIdRequested: (state: State) => boolean = get(`${BASE}.fetchDriverIdRequested`);
export const getFetchDriverIdFailed: (state: State) => boolean = get(`${BASE}.fetchDriverIdFailed`);

export const getFetchDriverRequested: (state: State) => boolean = get(`${BASE}.fetchDriverRequested`);
export const getFetchDriverFailed: (state: State) => boolean = get(`${BASE}.fetchDriverFailed`);
export const getFetchDriverEmpty: (state: State) => boolean = get(`${BASE}.fetchDriverEmpty`);
export const getDriverFile: (state: State) => DriverFile | undefined = get(`${BASE}.driverFile`);

export const getVehicleName: (state: State) => string | undefined = get(`${BASE}.vehicleName`);

export const getSubscriptions: (state: State) => string[] | undefined = get(`${BASE}.subscriptions`);
