import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Action } from 'redux';

import { Dispatch, State } from '../../types';
import { fetchVehicleFile as fetchVehicleFileFetch } from './fetchData/fetchVehicleFile';
import { fetchDriverId as fetchDriverIdFetch } from './fetchData/fetchDriverId';
import { fetchDriverIds as fetchDriverIdsFetch } from './fetchData/fetchDriverIds';
import { fetchDriverFile as fetchDriverFileFetch } from './fetchData/fetchDriverFile';
import { fetchVehicleName as fetchVehicleNameFetch } from './fetchData/fetchVehicleName';
import { fetchVehiclesWithSubscription as fetchVehiclesWithSubscriptionFetch } from './fetchData/fetchVehiclesWithSubscription';
import { getSelectedVehicleId, getDriverId, getDriverIds } from './selector';

export const PREFIX = 'EVENT';

export const sendMessage = (message: any) => {
    const referrer = document.referrer;
    if (referrer) {
        window.parent.postMessage(message, referrer);
    }
};

const receiveMessage = (event: MessageEvent, dispatchRemoteAction: (action: Action) => void) => {
    const actionType = event.data.type;
    if (!actionType) {
        return;
    }

    if (actionType.startsWith(PREFIX)) {
        dispatchRemoteAction(event.data);
    }
};

export const MessageHandler = React.memo(
    ({
        selectedVehicleId,
        driverId,
        driverIds,
        dispatchRemoteAction,
        fetchVehicleFile,
        fetchDriverId,
        fetchDriverIds,
        fetchDriverFile,
        fetchVehiclesWithSubscription,
        fetchVehicleName,
    }: Props) => {
        useEffect(() => {
            const listenerCallback = (event: MessageEvent) => receiveMessage(event, dispatchRemoteAction);
            window.addEventListener('message', listenerCallback, false);
            return () => window.removeEventListener('message', listenerCallback, false);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        useEffect(() => {
            if (selectedVehicleId) {
                fetchVehicleFile(selectedVehicleId);
                fetchVehicleName(selectedVehicleId);
            }
        }, [selectedVehicleId, fetchVehicleFile, fetchVehicleName]);

        useEffect(() => {
            if (selectedVehicleId) {
                fetchDriverId(selectedVehicleId);
            }
        }, [selectedVehicleId, fetchDriverId]);

        useEffect(() => {
            if (driverIds.length) {
                fetchDriverFile(driverIds);
            }
        }, [driverIds, fetchDriverFile]);

        useEffect(() => {
            if (driverId) {
                fetchDriverIds(driverId);
            }
        }, [driverId, fetchDriverIds]);

        useEffect(() => {
            fetchVehiclesWithSubscription();
        }, [fetchVehiclesWithSubscription]);

        return null;
    } // eslint-disable-line
);

type Props = ConnectedProps<typeof connector>;

const mapStateToProps = (state: State) => ({
    selectedVehicleId: getSelectedVehicleId(state),
    driverId: getDriverId(state),
    driverIds: getDriverIds(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatchRemoteAction: (action: Action) => dispatch(action),
    fetchVehicleFile: (vehicleId: string) => dispatch(fetchVehicleFileFetch(vehicleId)),
    fetchDriverId: (vehicleId: string) => dispatch(fetchDriverIdFetch(vehicleId)),
    fetchDriverIds: (driverId: string) => dispatch(fetchDriverIdsFetch(driverId)),
    fetchDriverFile: (driverIds: string[]) => dispatch(fetchDriverFileFetch(driverIds)),
    fetchVehiclesWithSubscription: () => dispatch(fetchVehiclesWithSubscriptionFetch()),
    fetchVehicleName: (vehicleId: string) => dispatch(fetchVehicleNameFetch(vehicleId)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
const MessageHandlerContainer = connector(MessageHandler);
export default MessageHandlerContainer;
