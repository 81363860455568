import { Action, handleActions } from 'redux-actions';

import {
    fetchVehicleFailed,
    fetchVehicleRequested,
    fetchVehicleSucceeded,
    fetchVehicleEmpty,
    hideSessionExpiredDialog,
    fetchSubscriptionsSucceeded,
    fetchDriverIdFailed,
    fetchDriverIdRequested,
    fetchDriverIdSucceeded,
    fetchDriverIdsSucceeded,
    fetchDriverFailed,
    fetchDriverRequested,
    fetchDriverSucceeded,
    fetchDriverEmpty,
    fetchVehicleNameRequested,
    fetchVehicleNameSucceeded,
} from './actions';
import { remoteActions } from './remoteActions';
import { DRIVER_IDS_SUCCEEDED, DriverFile, VehicleFile } from './types';

export interface AppState {
    widgetId?: string;
    visible: boolean;
    sessionExpiredAcknowledged: boolean;
    selectedVehicleId: string | false;
    subscriptions: string[] | false;
    vehicleFile: VehicleFile | undefined;
    fetchVehicleEmpty: boolean;
    fetchVehicleRequested: boolean | number;
    fetchVehicleFailed: boolean;
    driverId: string | undefined;
    driverIds: string[];
    driverDisplayName: string;
    fetchDriverIdRequested: boolean | number;
    fetchDriverIdFailed: boolean;
    driverFile: DriverFile | undefined;
    fetchDriverRequested: boolean | number;
    fetchDriverFailed: boolean;
    fetchDriverEmpty: boolean;
    vehicleName: string | undefined;
    fetchVehicleNameRequested: boolean | number;
}

export const initialState: AppState = {
    widgetId: undefined,
    visible: false,
    sessionExpiredAcknowledged: false,
    selectedVehicleId: false,
    subscriptions: false,
    vehicleFile: undefined,
    fetchVehicleEmpty: false,
    fetchVehicleRequested: false,
    fetchVehicleFailed: false,
    driverId: undefined,
    driverIds: [],
    driverDisplayName: '',
    fetchDriverIdRequested: false,
    fetchDriverIdFailed: false,
    driverFile: undefined,
    fetchDriverRequested: false,
    fetchDriverFailed: false,
    fetchDriverEmpty: false,
    vehicleName: undefined,
    fetchVehicleNameRequested: false,
};

type SetWidgetIdPayload = string;
type SetVisibleWidgetsPayload = string[];
interface SelectedVehicle {
    id: string;
}

const reducer = handleActions<
    AppState,
    VehicleFile & DriverFile & SelectedVehicle & SetWidgetIdPayload & SetVisibleWidgetsPayload & number & string
>(
    {
        [hideSessionExpiredDialog.toString()]: (state) => ({
            ...state,
            sessionExpiredAcknowledged: true,
        }),
        [fetchVehicleEmpty.toString()]: (state) => ({
            ...state,
            fetchVehicleRequested: false,
            fetchVehicleFailed: false,
            vehicleFile: undefined,
            fetchVehicleEmpty: true,
        }),
        [fetchVehicleFailed.toString()]: (state) => ({
            ...state,
            fetchVehicleRequested: false,
            fetchVehicleFailed: true,
            vehicleFile: undefined,
            fetchVehicleEmpty: false,
        }),
        [fetchVehicleRequested.toString()]: (state, action: Action<number>) => ({
            ...state,
            fetchVehicleRequested: action.payload,
            fetchVehicleFailed: false,
            vehicleFile: undefined,
            fetchVehicleEmpty: false,
        }),
        [fetchVehicleSucceeded.toString()]: (state, action: Action<VehicleFile>) => ({
            ...state,
            fetchVehicleRequested: false,
            fetchVehicleFailed: false,
            vehicleFile: action.payload,
            fetchVehicleEmpty: false,
        }),
        [fetchDriverIdFailed.toString()]: (state) => ({
            ...state,
            fetchDriverIdRequested: false,
            fetchDriverIdFailed: true,
            driverId: undefined,
        }),
        [fetchDriverIdRequested.toString()]: (state, action: Action<number>) => ({
            ...state,
            fetchDriverIdRequested: action.payload,
            fetchDriverIdFailed: false,
            driverId: undefined,
        }),
        [fetchDriverIdSucceeded.toString()]: (state, action: Action<string>) => ({
            ...state,
            fetchDriverIdRequested: false,
            fetchDriverIdFailed: false,
            driverId: action.payload,
        }),
        [fetchDriverIdsSucceeded.toString()]: (state, action: Action<DRIVER_IDS_SUCCEEDED>) => ({
            ...state,
            driverIds: action.payload.driverIds,
            driverDisplayName: action.payload.displayName,
        }),
        [fetchDriverEmpty.toString()]: (state) => ({
            ...state,
            fetchDriverRequested: false,
            fetchDriverFailed: false,
            fetchDriverEmpty: true,
            driverFile: undefined,
        }),
        [fetchDriverFailed.toString()]: (state) => ({
            ...state,
            fetchDriverEmpty: false,
            fetchDriverRequested: false,
            fetchDriverFailed: true,
            driverFile: undefined,
        }),
        [fetchDriverRequested.toString()]: (state, action: Action<number>) => ({
            ...state,
            fetchDriverEmpty: false,
            fetchDriverRequested: action.payload,
            fetchDriverFailed: false,
            driverFile: undefined,
        }),
        [fetchDriverSucceeded.toString()]: (state, action: Action<DriverFile>) => ({
            ...state,
            fetchDriverEmpty: false,
            fetchDriverRequested: false,
            fetchDriverFailed: false,
            driverFile: action.payload,
        }),
        [fetchSubscriptionsSucceeded.toString()]: (state, action: Action<string[]>) => ({
            ...state,
            subscriptions: action.payload,
        }),
        [fetchVehicleNameRequested.toString()]: (state, action: Action<number>) => ({
            ...state,
            vehicleName: undefined,
            fetchVehicleNameRequested: action.payload,
        }),
        [fetchVehicleNameSucceeded.toString()]: (state, action: Action<string>) => ({
            ...state,
            vehicleName: action.payload,
            fetchVehicleNameRequested: false,
        }),
        [remoteActions.setWidgetId.toString()]: (state, action: Action<SetWidgetIdPayload>) => {
            return { ...state, widgetId: action.payload };
        },
        [remoteActions.setVisibleWidgets.toString()]: (state, action: Action<SetVisibleWidgetsPayload>) => {
            // eslint-disable-next-line
            const visible = action.payload.includes(state.widgetId!!);
            return visible !== state.visible ? { ...state, visible } : state;
        },
        [remoteActions.assetSelected.toString()]: (state, action: Action<SelectedVehicle>) => ({
            ...state,
            selectedVehicleId: action.payload.id,
            driverName: undefined,
            fetchVehicleNameRequested: false,
        }),
    },
    // eslint-disable-next-line
    initialState
);

export default reducer;
