import { DriversAnswer, Driver, Identification } from '../types';
import { Thunk, Dispatch } from '../../../types';
import { fetchDriverIdsSucceeded } from '../actions';
import { fetchData } from '../../../configuration/setup/fetch';
import { config } from '../../../config';
import { get } from 'lodash';
import { store } from '../../..//configuration/setup/store';

type GET_DRIVERS_RETURN = {
    driverIds?: string[];
    displayName?: string;
};

const getAllIds = (item: Driver): string[] => {
    const identifications = get(item, '_embedded.identifications', []);

    const singleDriverIds = identifications
        .filter((identification: Identification) => identification.identification_type === 'rio-driver-identification')
        .map((identification: Identification) => identification.identification);

    return [...singleDriverIds, item.id];
};

const doFetches = async (path: string, driverId: string): Promise<GET_DRIVERS_RETURN> => {
    const answer = await fetchData<DriversAnswer>(path);

    if (answer instanceof Error) {
        return {};
    } else {
        const foundDriver = answer.items.find((item) => {
            const allIds = getAllIds(item);

            return allIds.includes(driverId);
        });

        if (!foundDriver) {
            const nextUrl = get(answer, '_links.next.href', '');
            if (nextUrl) {
                return doFetches(nextUrl, driverId);
            }

            return {};
        }

        return {
            driverIds: getAllIds(foundDriver),
            displayName: foundDriver.display_name,
        };
    }
};

const getDriver = async (driverId: string): Promise<GET_DRIVERS_RETURN> => {
    const requestUrl = `${config.backend.REACT_APP_DRIVER_ADMINISTRATION_URL}?embed=(identifications)`;

    return doFetches(requestUrl, driverId);
};

export const fetchDriverIds =
    (driverId: string): Thunk =>
    async (dispatch: Dispatch) => {
        try {
            const { driverIds = [driverId], displayName = '' } = await getDriver(driverId);

            if (store.getState().app.driverId === driverId) {
                dispatch(fetchDriverIdsSucceeded({ driverIds, displayName }));
            }
        } catch (e) {
            dispatch(fetchDriverIdsSucceeded({ driverIds: [driverId], displayName: '' }));
        }
    };
