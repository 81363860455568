import { DriverFile, DriverAnswer } from '../types';
import { Thunk, Dispatch } from '../../../types';
import { fetchDriverFailed, fetchDriverRequested, fetchDriverSucceeded, fetchDriverEmpty } from '../actions';
import { fetchData } from '../../../configuration/setup/fetch';
import { config } from '../../../config';
import { get, isEqual } from 'lodash';
import { store } from '../../../configuration/setup/store';

export const fetchDriverFile =
    (driverIds: string[]): Thunk =>
    async (dispatch: Dispatch) => {
        try {
            const requestDate = Date.now();
            dispatch(fetchDriverRequested(requestDate));

            const requestUrl = `${config.backend.FILE_ARCHIVE_URL}`;
            const answer = await fetchData<DriverAnswer>(requestUrl, {
                method: 'POST',
                body: JSON.stringify({
                    externalDriverReferences: driverIds,
                    fileType: 'Driver',
                }),
            });

            const driverFile = get(answer, 'items[0]');
            const driverEmpty = isEqual(get(answer, 'items'), []);
            if (store.getState().app.fetchDriverRequested === requestDate) {
                if (driverEmpty) {
                    dispatch(fetchDriverEmpty());
                } else {
                    if (driverFile) {
                        dispatch(fetchDriverSucceeded(driverFile as DriverFile));
                    } else {
                        dispatch(fetchDriverFailed());
                    }
                }
            }
        } catch (e) {
            dispatch(fetchDriverFailed());
        }
    };
