import { combineReducers } from 'redux';
import { History } from 'history';
import { configReducer, langReducer, loginReducer /* , tokenHandlingReducer */ } from './configuration';
import tokenHandlingReducer from './configuration/tokenHandling/reducer'; // TODO make import from ./configuration work
import reducer from './features/app/reducer';
import { connectRouter } from 'connected-react-router';

export const rootReducer = (history: History) =>
    combineReducers({
        config: configReducer,
        lang: langReducer,
        app: reducer,
        login: loginReducer,
        router: connectRouter(history),
        tokenHandling: tokenHandlingReducer,
    });
