import defaultTo from 'lodash/fp/defaultTo';
import flow from 'lodash/fp/flow';
import head from 'lodash/fp/head';
import split from 'lodash/fp/split';

const DEFAULT_LOCALE = 'en-GB';

const supportedLocaleMap = {
    en: 'en-GB',
    'en-GB': 'en-GB',
    'en-US': 'en-GB',

    de: 'de-DE',
    'de-DE': 'de-DE',

    cs: 'cs-CZ',
    'cs-CZ': 'cs-CZ',

    da: 'da-DK',
    'da-DK': 'da-DK',

    es: 'es-ES',
    'es-ES': 'es-ES',

    fr: 'fr-FR',
    'fr-FR': 'fr-FR',

    it: 'it-IT',
    'it-IT': 'it-IT',

    nl: 'nl-NL',
    'nl-NL': 'nl-NL',

    pl: 'pl-PL',
    'pl-PL': 'pl-PL',

    ro: 'ro-RO',
    'ro-RO': 'ro-RO',

    bg: 'bg-BG',
    'bg-BG': 'bg-BG',

    hr: 'hr-HR',
    'hr-HR': 'hr-HR',

    fi: 'fi-FI',
    'fi-FI': 'fi-FI',

    hu: 'hu-HU',
    'hu-HU': 'hu-HU',

    lt: 'lt-LT',
    'lt-LT': 'lt-LT',

    nb: 'nb-NO',
    'nb-NO': 'nb-NO',

    pt: 'pt-PT',
    'pt-PT': 'pt-PT',

    sk: 'sk-SK',
    'sk-SK': 'sk-SK',

    sl: 'sl-SI',
    'sl-SI': 'sl-SI',
    'sl-SL': 'sl-SI',

    se: 'sv-SE',
    'sv-SE': 'sv-SE',

    el: 'el-GR',
    'el-GR': 'el-GR',

    et: 'et-EE',
    'et-EE': 'et-EE',

    lv: 'lv-LV',
    'lv-LV': 'lv-LV',
};

const extractLanguage = flow(defaultTo(DEFAULT_LOCALE), split('-'), head);

const DEFAULT_LANG = extractLanguage(DEFAULT_LOCALE);

export { DEFAULT_LANG, DEFAULT_LOCALE, extractLanguage, supportedLocaleMap };
